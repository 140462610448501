.list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    color: #68697e;

    & > a {
      text-decoration: none;
      color: inherit;

      &:hover {
        cursor: pointer;
        color: #9f9ca5;
      }
    }
  }

  & > li:not(:last-child) {
    margin-right: 24px;
  }
}
