.content {
  display: flex;
  position: fixed;
  flex-direction: row;
  min-height: var(--app-height);

  @media only screen and (max-width: 600px) {
    /* @media only screen and (max-width: 768px) { */
    flex-direction: column-reverse;
    justify-content: flex-end;
    position: unset;
  }

  @media (orientation: landscape) and (height < 750px) {
    position: unset;
  }
}
