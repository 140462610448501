.main {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f4ebe1;
  z-index: 5;
  transition: padding 150ms linear;

  @media (min-width: 600px) {
    width: 50vw;
    height: 100vh;
    padding: 0 52px;
  }

  @media (orientation: landscape) and (max-height: 750px) {
    height: auto !important;
    padding-top: 86px !important;
    padding-bottom: 96px !important;

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media only screen and (max-width: 600px) {
    /* @media (max-width: 768px) { */
    align-items: baseline;
    width: 100vw;
    height: auto;
    padding: 40px 16px;
    justify-content: flex-start;
    z-index: inherit;

    & > div > .store-btn-wrapper {
      flex-direction: column;
    }
  }

  @media (min-width: 1200px) {
    padding: 0 105px;
  }

  @media (min-width: 1600px) {
    padding: 0 210px;
  }

  & > span {
    max-width: 540px;
    width: 100%;
  }
}

.store-btn-wrapper {
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
}
