.text-part {
  display: inline-block;
  transform-origin: top left;
  will-change: transform;

  &:not(:last-child) {
    margin-right: 0.5ch;
  }
}

.intro-header {
  font-family: "Goudy Heavyface Std", sans-serif;
  color: #444664;
  font-size: 48px;
  margin-bottom: 0;

  @media (max-width: 600px) {
    font-size: 32px;
    margin-top: 0;
  }

  @media (orientation: landscape) and (height < 750px) {
    font-size: 40px;

    @media (max-width: 786px) {
      font-size: 28px;
    }
  }
}

.intro-p {
  max-width: 497px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: #444664;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }

  @media (orientation: landscape) and (height < 750px) {
    @media (max-width: 786px) {
      font-size: 18px;
    }
  }
}
