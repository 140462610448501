.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  z-index: 10;
  transition: padding 150ms linear;

  & > img {
    height: 49px;
    width: 49px;
  }

  @media (orientation: landscape) and (max-height: 750px) {
    position: absolute !important;
  }

  @media (max-width: 768px) {
    padding: 31px 16px 0;
    position: absolute;
  }

  @media (min-width: 769px) {
    padding: 32px 52px 0;

    /* position: fixed; */
    position: absolute;
  }

  @media (min-width: 1024px) {
    padding: 32px 52px 0;
  }

  @media (min-width: 1200px) {
    padding: 32px 105px 0;
  }

  @media (min-width: 1600px) {
    padding: 32px 105px 0 210px;
  }

  @media (min-width: 1920px) {
    padding: 32px calc((50vw - 750px) / 2) 0 calc((50vw - 540px) / 2);
  }
}
