.box {
  position: absolute;
  will-change: transform;
  z-index: 2;

  &:nth-child(1) {
    left: 166px;
    top: 68px;

    @media only screen and (max-width: 428px) {
      left: 85px;
      top: 35px;
    }
  }

  &:nth-child(2) {
    right: -9px;
    top: 140px;

    @media only screen and (max-width: 428px) {
      right: -5px;
      top: 72px;
    }
  }

  &:nth-child(3) {
    right: 79px;
    bottom: 73px;

    @media only screen and (max-width: 428px) {
      right: 40px;
      bottom: 33px;
    }
  }

  &:nth-child(4) {
    left: 130px;
    top: 102px;

    @media only screen and (max-width: 428px) {
      left: 67px;
      top: 52px;
    }
  }

  &:nth-child(5) {
    left: 62px;
    bottom: 46px;

    @media only screen and (max-width: 428px) {
      left: 32px;
      bottom: 20px;
    }
  }

  /* blue boxes from left to right */
  &:nth-child(6) {
    /* left: -28px; */
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.blue-r {
  z-index: 3;
  position: absolute;
  top: 50%;
  right: 91px;
  width: 78px;
  height: 75px;
  transform: translate(44%, -46%);

  /* background-color: white; */

  & > div {
    position: relative;
    width: 100%;
    height: 100%;

    & > .box {
      &:nth-child(1) {
        left: 0;
        bottom: 0;
        right: unset;
        top: unset;
      }

      &:nth-child(2) {
        top: 0;
        right: 0;
        left: unset;
        bottom: unset;
      }
    }
  }
}

.yellow-box {
  background: #ffb63e;
  opacity: 0.3;
  border-radius: 20px;

  @media only screen and (max-width: 428px) {
    border-radius: 30%;
  }
}

.blue-box {
  background: #323781;
  opacity: 0.1;
  border-radius: 12px;
  transition: 0.5s;
  z-index: 3;

  @media only screen and (max-width: 428px) {
    border-radius: 30%;
  }
}

.box-74 {
  height: 74px;
  width: 74px;

  @media only screen and (max-width: 428px) {
    height: 38px;
    width: 38px;
  }
}

.box-54 {
  height: 54px;
  width: 54px;

  @media only screen and (max-width: 428px) {
    height: 27px;
    width: 27px;
  }
}

.box-56 {
  height: 56px;
  width: 56px;

  @media only screen and (max-width: 428px) {
    height: 29px;
    width: 29px;
  }
}

.box-41 {
  height: 41px;
  width: 41px;

  @media only screen and (max-width: 428px) {
    height: 21px;
    width: 21px;
  }
}
