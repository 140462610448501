.container {
  display: grid;
  grid-template-columns: 105px repeat(12, 1fr) 105px;
  grid-template-rows: auto;
  grid-gap: 30px;
  overflow-x: hidden;

  @media (min-width: 1230px) {
    grid-template-columns: calc((100vw - 1230px) / 2) repeat(12, 1fr) calc((100vw - 1230px) / 2);
  }

  @media (max-width: 667px) {
    grid-template-columns: repeat(6, 1fr);
  }

  & .box-container-start {
    position: relative;
    grid-column: 1/span 2;
  }

  & .box-container-end {
    position: relative;
    grid-column: span 2/ -1;
    background: red;
  }

  & .bg {
    grid-column: 2/-2;
  }

  & .privacy {
    grid-column: 4 / span 8;
    padding-top: 158px;
    padding-bottom: 84px;
    background-color: #f4ebe1;
    z-index: 3;

    @media (max-width: 767px) {
      grid-column: 2/-2;
    }

    @media (max-width: 667px) {
      grid-column: 1/-1;
      padding-top: 97px;
      padding-bottom: 40px;
      margin: 0 16px;
    }

    & > div {
      color: #444664;
      margin: 50px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & h1 {
        font-family: "Goudy Heavyface Std", sans-serif;
        font-size: 48px;
        line-height: 54px;
        margin: 0;

        @media (max-width: 850px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      & h2 {
        font-family: "Goudy Heavyface Std", sans-serif;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 0;

        @media (max-width: 850px) {
          font-size: 22px;
          line-height: 32px;
        }
      }

      & p {
        font-family: "Nunito Sans", sans-serif;
        font-size: 20px;
        line-height: 32px;
        margin-top: 8px;

        @media (max-width: 850px) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      & .mb-0 {
        margin-bottom: 0;
      }

      & ol {
        font-family: "Nunito Sans", sans-serif;
        font-size: 20px;
        line-height: 32px;
        margin-top: 0;
        padding-left: 0;
        list-style: none;

        @media (max-width: 850px) {
          font-size: 16px;
          line-height: 26px;
        }

        & li {
          counter-increment: counter;
          display: flex;
        }

        & li::before {
          font-family: "Goudy Heavyface Std", sans-serif;
          content: counter(counter);
          text-align: center;
          line-height: 20px;
          color: #fdfbf7;
          background-color: #ffb63e;
          border-radius: 25%;
          padding: 8px 3px 0;
          margin-right: 15px;
          height: 32px;
          width: 32px;
        }

        & li:nth-child(-n+9)::before {
          content: "0"counter(counter);
        }
      }
    }
  }
}
