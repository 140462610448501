.not-found {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  overflow: hidden;

  & h1 {
    font-family: "Goudy Heavyface Std", sans-serif;
    color: #444664;
  }
}
