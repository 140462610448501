@font-face {
  font-family: "Goudy Heavyface Std";
  font-style: normal;
  src: url("./resources/fonts/Goudy_Heavyface_Std_Regular.otf");
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #f4ebe1;
}

.app {
  min-height: var(--app-height);

  @media (orientation: landscape) and (height < 750px) {
    position: relative;
  }
}
