.footer {
  display: flex;
  position: fixed;
  flex-direction: column;
  padding: 0 105px 32px;
  width: 100vw;
  bottom: 0;
  z-index: 10;
  transition: padding 150ms linear;

  @media (max-width: 600px) {
    position: static;
    padding: 0 0 54px 16px;
    background-color: #f4ebe1;
  }

  @media (min-width: 600px) {
    padding: 0 105px 32px 52px;
    position: fixed;
  }

  @media (orientation: landscape) and (max-height: 750px) {
    position: absolute !important;
    bottom: 0;

    @media (max-width: 768px) {
      padding: 0 0 16px 16px;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 52px 32px;
  }

  @media (min-width: 1200px) {
    padding: 0 105px 32px;
  }

  @media (min-width: 1600px) {
    padding: 0 105px 32px 210px;
  }

  @media (min-width: 1920px) {
    padding: 0 105px 32px calc((50vw - 540px) / 2);
  }

  &.footer-pp {
    position: unset;
  }

  & > .bottom-wave {
    position: relative;
    margin-bottom: 8px;
  }
}
