.store-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5f67ec;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #fdfbf7;
  border: solid #323781;
  border-width: 2px 2px 4px;
  border-radius: 50px;
  height: 50px;
  padding: 8px 24px 8px 0;

  &:hover {
    background-color: #323781;
    border: solid #262a63;
    border-width: 2px 2px 4px;
    cursor: pointer;
  }

  &:active {
    border-width: 4px 2px 2px;
  }

  &.apple-btn {
    margin-right: 40px;

    @media only screen and (max-width: 1200px) {
      justify-content: start;
      margin-right: 198px;
      margin-bottom: 16px;
      padding: 0;
      width: 161px;

      & > div {
        margin-left: 8px;
        margin-right: 12px;
      }
    }
  }

  &.playstore-btn {
    @media only screen and (max-width: 1200px) {
      justify-content: start;
      margin-right: 131px;
      padding: 0;
      width: 178px;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdfbf7;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 12px;

    & > .play-logo {
      height: 14px;
      width: 14px;
    }

    & > .apple-logo {
      width: 14px;
      height: 16.14px;
    }
  }
}
