:root {
  --wave-width: 1000px;
}

.mockup {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  overflow: hidden;
  width: 50vw;
  height: 100vh;
  background-color: #ffdca3;
  justify-content: center;
  padding: 0 105px;

  @media (orientation: landscape) and (max-height: 750px) {
    height: auto !important;
  }

  @media (max-width: 1200px) {
    padding: 0 52px;
  }

  @media (max-width: 600px) {
    /* @media only screen and (max-width: 768px) { */
    height: 100%;
    min-height: 50vh;
    width: 100vw;
    align-items: center;
    padding: 88px 0 70px;

    & > picture {
      width: 254px;
    }
  }

  & > .blue-wave {
    position: absolute;
    background-image: url("@resources/blue_wave.svg");
    background-repeat: repeat-x;
    background-size: var(--wave-width);
    background-position: left center;
    z-index: 2;

    /* aspect-ratio: 1036 / 270; */

    height: 270px;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    animation: wave 10s infinite linear;

    @media only screen and (max-width: 600px) {
      /* @media only screen and (max-width: 768px) { */
      background-size: 500px;
      height: 200px;
    }
  }

  & > picture {
    max-width: 100%;
    height: 100%;
    z-index: 4;

    & > img {
      object-fit: contain;
      height: 100%;
      max-width: 100%;
      object-position: center center;
    }

    @media (min-width: 1920px) {
      max-width: 750px;
    }
  }
}

@keyframes wave {
  from {
    background-position: left center;
  }

  to {
    background-position: calc(var(--wave-width) * -1) center;
  }
}
